import React from 'react';
import { Link } from 'gatsby';

import config from '../../config';

import picPosition from '../assets/images/landscapes/village.jpg';
import picEvent from '../assets/images/landscapes/cabane_alpage.jpg';
import picGodMother from '../assets/images/actors/stephanie_bodet.jpg';
import picActors from '../assets/images/actors/aurelie.jpg';
import picResources from '../assets/images/landscapes/chapelle.jpg';

const picConcerts = [
  picEvent,
  picEvent
]

const picDonaters = [
  picGodMother,
  picGodMother
]

const picArtists = [
  picGodMother,
  picGodMother
]

const picTeam = [
  picGodMother,
  picGodMother
]

export default function Card(props) {

  let current = {};

  const type = props.type;

  if (props.type == 'concerts')
  {
    current.path = '/concert';
    current.title = 'Concerts à part';
    current.image = picEvent;
    current.tagline = 'Des projets en musique à part.';  
    
  }
  else if (props.type == 'mouvements')
  {
    current.path = '/mouvement';
    current.title = 'Mouvements à part';
    current.image = picEvent;
    current.tagline = 'Des projets en mouvements à part ...';  
    
  }
  else if (props.type == 'position')
  {
    current.path = '/position';
    current.title = 'Notre position';
    current.image = picPosition;
    current.tagline = 'Un espace pour ceux qui produisent avec l\'écologie';  
    
  }
  else if (props.type == 'actors')
  {
    current.path = '/actors';
    current.title = 'Les partenaires';
    current.image = picActors;
    current.tagline = 'Ils se sont investis pour des événements passés ou à venir.';  
    
  }
  else if (props.type == 'resources')
  {
    current.path = '/resources';
    current.title = 'Lien et ressources';
    current.image = picResources;
    current.tagline = 'Pour mieux comprendre la problématique';  
    
  }
  else if (props.type == 'custom')
  {
    current.path = props.path;
    current.title = props.title;
    current.image = props.image;
    current.tagline = props.tagline;
  }
  else if (props.type == 'agenda')
  {
    current.path = props.path;
    current.title = props.title;
    current.image = picConcerts[props.image];
    current.tagline = props.tagline;
    current.date = props.date;
    current.place = props.place;
  }
  else if (props.type == 'artiste')
  {
    current.path = props.path;
    current.title = props.title;
    current.image = picArtists[props.image];
    current.tagline = props.tagline;
    current.date = props.statut;
    current.place = props.place;
    current.ig = props.ig;
  }
  else if (props.type == 'donater')
  {
    current.title = props.name;
    current.state = props.state === "time" ? "Bénévole" : "Donateur";
    current.image = picDonaters[props.image];
    current.tagline = props.tagline;
  }
  else if (props.type == 'team')
  {
    current.title = props.name;
    current.state = props.state;
    current.image = picTeam[props.image];
    current.tagline = props.tagline;
  }

  
  return (
     <article>
       <Link to={current.path} className="image">
         <img src={current.image} alt="" />
       </Link>
       <h3 className="major">{current.title}</h3>
       {(type === "agenda" || type === "artiste") &&
         <div className="infos"><h4>{current.date}</h4><h4>{current.place}</h4></div>
       }
       {(type === "donater" || type === "team") &&
         <div className="infos"><h4>{current.state}</h4></div>
       }
       <p>{current.tagline}</p>
       {type !== "donater" && type !== "team" && type !== "artiste" &&
          <Link to={current.path} className="special">découvrir</Link>
       }
       {type === "team" &&
          <Link to="/#contact" className="special">contacter</Link>
       }
       {(type === "artiste" && props.ig !=="") &&
			<ul className="actions">
				<li><Link to="https://ig.com/{current.ig}" className="button icon fa-link">Site web</Link></li>
				<li><Link to="/#" className="button icon fa-instagram">Instagram</Link></li>
			</ul>
       }
     </article>
  );
}
