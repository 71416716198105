import React from 'react';

import Layout from '../components/Layout';
import Card from '../components/Card';
import Video from '../components/Video';

import config from '../../config';

import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic5 from '../assets/images/pic05.jpg';
import pic6 from '../assets/images/pic06.jpg';
import pic7 from '../assets/images/pic07.jpg';
import pic8 from '../assets/images/pic08.jpg';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>Interviews & reportages</h2>
          <p>Ils nous soutiennent !</p>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">Presse</h3>
          
          <section className="features">
            <Video url="https://www.youtube.com/watch?v=S5qyCBDNtuE" />
          </section>

          <section>
						<h3 className="major">Média</h3>
						<div className="box alt">
							<div className="row gtr-uniform">
								<div className="col-4"><span className="image fit"><img src={pic5} alt="" /></span></div>
								<div className="col-4"><span className="image fit"><img src={pic6} alt="" /></span></div>
								<div className="col-4"><span className="image fit"><img src={pic7} alt="" /></span></div>
								<div className="col-4"><span className="image fit"><img src={pic7} alt="" /></span></div>
								<div className="col-4"><span className="image fit"><img src={pic5} alt="" /></span></div>
								<div className="col-4"><span className="image fit"><img src={pic6} alt="" /></span></div>
								<div className="col-4"><span className="image fit"><img src={pic6} alt="" /></span></div>
								<div className="col-4"><span className="image fit"><img src={pic7} alt="" /></span></div>
								<div className="col-4"><span className="image fit"><img src={pic5} alt="" /></span></div>
							</div>
						</div>
          </section>
          
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
